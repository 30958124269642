import React, { Component } from 'react';

import './style.scss';

class Banner extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        {/*generated code*/}
        {/* Banner with image slides */}
        <section className="hero firstSec section colored" id={ this.props.id }>
          <div className="container">
            <div className="col-xs-6 col-md-4 profilePic">
              <img src={ process.env.PUBLIC_URL + "/images/me.jpg" } alt="" className="img-circle"/>
            </div>
            {/* TODO: change images based on time, month, etc */}
            {/* TODO: update the image size */}
            <div className="heading">
              <h1>NAVLEEN SINGH</h1>
              <h3>Entrepreneur <br/> Full Stack Developer <br/> DevOps Engineer</h3>
              <a target="_blank" href={ process.env.PUBLIC_URL + "/files/Navleen_Resume.pdf" } className="borderBtn">VIEW RESUME</a>
              {/* <a download href={ process.env.PUBLIC_URL + "files/Navleen_Resume.pdf" } className="borderBtn">DOWNLOAD RESUME</a> */}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}


export default Banner;