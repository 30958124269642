import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';

import Banner from './components/Banner';
import Me from './components/Me';
import Navigation from './components/Navigation';

import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (

      <HashRouter hashType="noslash">
        <div className="App">
          <Navigation/>
          <Banner id="home"/>
          <Me id="me"/>
          {/* <Banner ref="welcome"/> */}
        </div>
      </HashRouter>
    );
  }
}

export default App;
