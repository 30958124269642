import React, { Component } from 'react';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom'


import './style.scss';

class Navigation extends Component {
  render() {
    // const { location } = this.props;
    return (
      <Navbar fluid inverse fixedTop>
        <Navbar.Header>
          <Navbar.Brand>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav>
            <LinkContainer to="/home" activeClassName="active">
              <NavItem eventKey={1}>
                HOME
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/about" activeClassName="active">
              <NavItem eventKey={2}>
                ABOUT
              </NavItem>
            </LinkContainer>
            <NavItem disabled eventKey={3} href="#">
              SKILLS
            </NavItem>
            <NavItem disabled eventKey={4} href="#">
              EXPERIENCE
            </NavItem>
            <NavItem disabled eventKey={5} href="#">
              PORTFOLIO
            </NavItem>
            <NavItem disabled eventKey={6} href="#">
              CONTACT
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
};

export default withRouter(Navigation);