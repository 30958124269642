import React, { Component } from 'react';

import './style.scss';

class Banner extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        {/*generated code*/}
        {/* Banner with image slides */}
        <section id={ this.props.id }>
          <div id="slides" className="slides">
            {process.env.PUBLIC_URL + "/images/slides/1.jpg"}
            <div className="overlay" />
            {/* TODO: change images based on time, month, etc */}
            {/* TODO: update the image size */}
            <div className="slides-container">
              <img src={ process.env.PUBLIC_URL + "/images/slides/1.jpg" } alt="1" />
              <img src={ process.env.PUBLIC_URL + "/images/slides/2.jpg" } alt="2" />
              <img src={ process.env.PUBLIC_URL + "/images/slides/3.jpg" } alt="3" />
            </div>
            <div className="welcome-message">
              <div className="heading">
                <p>HI, I'M NAVLEEN SINGH</p>
                <p className="small">Full Stack Developer from Canada</p>
              </div>
              <a href={ process.env.PUBLIC_URL + "#me" } className="borderBtn">MORE ABOUT ME</a>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}


export default Banner;